<template>
	<SectionHeader v-if="member && member.name" :image="null" title="Mitglied" :page="member.name" />
	<MemberItemSingle :member="member"/>
</template>

<script>
import MemberItemSingle from '@/views/MemberItemSingle.vue'
import SectionHeader from '@/views/SectionHeader.vue'
export default {
	name: 'Mitglied',
	components: {
		MemberItemSingle,
		SectionHeader
	},
	props: ["id"],
	methods: {
	},
	created() {
		this.$store.dispatch('loadMembers');
	},
	computed: {
		member() {
			return this.$store.state.members.find(m => m.id == this.id);
		},
		error() {
			return this.$store.state.loadingError;
		},
		loading() {
			return this.$store.state.loadingStatus;
		}
	}
};
</script>