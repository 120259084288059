<template>
	<div class="container">
		<section v-if="member && member.id" :id="member.id">
			<div class="row p-2">
				<div class="col-md-3">
					<div class="row">
						<div class="card text-center">
							<div class="card-header">
								<h5 v-text="member.name" />
							</div>
							<div class="card-body">
								<img v-if="member.picture" class="img img-thumbnail" :src="ProfilePicture">
								<i :class="MemberSymbol"></i>
								<p v-if="member.joined" v-text="getDate(member.joined)" />
								<p v-text="member.description" />
								<p v-text="id" />
							</div>
							<div class="card-footer">
								<div class="nav mb-1">
									<div class="nav tag-style">
										<router-link v-if="member.group1" :to="{ name: 'Arbeitsgruppe', params: { slug: member.group1.slug } }" v-text="member.group1.post.title" class="text-reset"></router-link>
										<router-link v-if="member.group2" :to="{ name: 'Arbeitsgruppe', params: { slug: member.group2.slug } }" v-text="member.group2.post.title" class="text-reset"></router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-8 ms-4">
					<div class="row">
						<div class="card">
							<div class="card-header">
								<h5>Beiträge von {{ member.name }}</h5>
							</div>
							<div class="card-body">
								<div v-if="PostsByMember.length == 0">
									<p>Keine Beiträge gefunden</p>
								</div>
								<div v-else v-for="(post, index) in PostsByMember" :key="post.id">
									<router-link :to="{ name: 'Beitrag', params: { id: post.id } }" v-text="(index+1) +': ' + post.title" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
import moment from 'moment'
import _ from 'lodash'
export default {
	name: 'MemberItemSingle',
	props: ['member'],
	methods: {
		getDate(date)
		{
			return "Beitritt: "+ moment(date, "YYYY-MM-DD").fromNow();
		},
	},
	created() {
		this.$store.dispatch('loadPosts');
	},
	computed: {
		MemberSymbol()
		{
			switch(this.member.type)
			{
				case "1":
					return "m-2 fad fa-2x fa-user-crown text-success";
				case "2":
					return "m-2 fad fa-2x fa-user-crown text-success";
				case "3":
					return "m-2 fad fa-2x fa-user text-success";
				case "4":
					return "m-2 fad fa-2x fa-user text-success";
				default:
					return "m-2 fad fa-2x fa-user text-muted";
			}
		},
		ProfilePicture()
		{
			return (this.member.picture) ? `https://api.bs-rt.de/assets/${this.member.picture.id}?key=thumbnail-300` : 'https://via.placeholder.com/250?text=Kein+Bild+vorhanden';
		},
		PostsByMember()
		{
			return _.orderBy(_.filter(this.$store.state.posts, ['creator.id', this.member.id]).filter(q => q.category == 'news' || q.category == 'review' || q.category == 'subpost'), 'date_created', 'desc');
		}
	},
	mounted() {
		window.scrollTo(0, 0)
	}
};
</script>
<style scoped>
p:first-letter {
	text-transform:capitalize;
}
</style>